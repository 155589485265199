import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../styles/scss/components/title.module.scss'
import { supSub } from '../services/util'

export default class Index extends Component {
    render() {
        const { value, gray, yellow, size, style, alignLeft, className, children, titleType = 'h1' } = this.props;
        const ResultType = titleType;
        let customStyle = style || {};
        if (size) customStyle.fontSize = size;
        if (!value && !children) return null;
        return (
            <ResultType
                className={cn(styles.title, className, { [styles.gray]: gray, [styles.yellow]: yellow, [styles.alignLeft]: alignLeft })}
                style={customStyle}
                dangerouslySetInnerHTML={{__html: supSub(value) || children }}>
            </ResultType>
        );
    }
}
